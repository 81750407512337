import { onViewportChange } from "../../common/js/Responsive"

document.addEventListener('DOMContentLoaded', () => {
  const dateRangeDropDown = document.querySelector('.refine-search-filter-form .daterange-dropdown')
  const dateCalender = document.querySelector('.refine-search-filter-form .date-calendar')

  if (dateRangeDropDown && dateCalender) {
    dateRangeDropDown.addEventListener('change', () => {
      if (dateRangeDropDown.value === 'start-end') {
        dateCalender.style.display = 'flex'
      } else {
        dateCalender.style.display = 'none'
        dateCalender.querySelector('#datepickerFrom').value = ''
        dateCalender.querySelector('#datepickerTo').value = ''
      }
    })

    if (window.innerWidth <= 767) {
      dateCalender.style.display = dateRangeDropDown.value === 'start-end' ? 'flex' : 'none'
    }

    onViewportChange(viewport => {
      if (viewport == 'mobile') {
        dateCalender.style.display = dateRangeDropDown.value === 'start-end' ? 'flex' : 'none'
      } else {
        dateCalender.style.display = 'flex'
      }
    })
  }
})